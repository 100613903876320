<template>

  <nav class="navbar navbar-expand-lg bg-white custom-fixed-navbar" 
       :class="[{ 'fixed-top': isScrolled, 'shadow': isScrolled }]"> 
		
		<div class="container-fluid">
			
				<a class="navbar-brand" href="https://ksrservices.co/">
					<img class="logo-dark" src="/images/logo.png" alt="KSR Services" />
				</a>
				<span class="navbar-divider d-none d-lg-block"></span>
				<button class="navbar-toggler me-2" 
                type="button" 
                @click="toggleNavbar"
                :class="{ collapsed: !isNavbarOpen }"
								aria-controls="navbarToggleContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>


			<div id="navbarToggleContent" class="collapse navbar-collapse" :class="{ show: isNavbarOpen }">

				<ul id="menu-primary-navbar" class="navbar-nav ms-auto">

					<li id="nav-menu-item-1" class="nav-item">
						<a class="nav-link menu-item-type-post_type menu-item-object-page" href="#header" @click.prevent="scrollTo('#header')">
							{{ $t('menu.welcome') }}
						</a>
					</li>

					<li id="nav-menu-item-2" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#who-we-are" @click.prevent="scrollTo('#who-we-are')">
							{{ $t('menu.who-we-are') }}
						</a>
					</li>

					<li id="nav-menu-item-3" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#our-services" @click.prevent="scrollTo('#our-services')">
							{{ $t('menu.services') }}
						</a>
					</li>

					<li id="nav-menu-item-4" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#our-clients" @click.prevent="scrollTo('#our-clients')">
							{{ $t('menu.customers') }}
						</a>
					</li>

          <li id="nav-menu-item-5" class="nav-item">
            <div class="nav-link">
              <a class="btn btn-primary btn-round" href="#rendez-vous" @click.prevent="scrollTo('#rendez-vous')">  
                {{ $t('menu.contact-us') }}
              </a>
            </div>
          </li>

					<!-- change language -->
          <li id="nav-menu-item-6" class="nav-item">

						<div class="nav-link dropdown cursor-pointer" >   
      
							<button class="dropdown-toggle" type="button" id="dropdownMenuProfile" data-bs-toggle="dropdown" aria-expanded="false">
								<span>{{selectedLanguage}}</span>
							</button>
							
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuProfile" ref="DropdownMenu">
								<li v-if="selectedLanguage != 'FR'" class="dropdown-item" @click="changeLanguage('fr', 'FR')"><a> FR </a></li>
								<li v-if="selectedLanguage != 'EN'" class="dropdown-item" @click="changeLanguage('en', 'EN')"><a> EN </a></li>
							</ul>

						</div>

					</li>

				</ul>

			</div>

		</div>

	</nav>

	<!-- Main Content -->
	<main class="main-content">

		<!-- header -->
		<header id="header" class="header h-fullscreen">
			
      <!--  -->
      <video class="video-header" autoplay="" muted="" playsinline="" loop="" preload="auto" oncontextmenu="return false;">
        <source src="../assets/video/c_fill,g_auto,w_576,h_1024.mp4" media="(max-width: 576px)">
        <source src="../assets/video/c_fill,g_auto,w_768,h_576.mp4" media="(max-width: 768px)">
        <source src="../assets/video/c_fill,g_auto,w_992,h_558.mp4" media="(max-width: 992px)">
        <source src="../assets/video/c_fill,g_auto,w_1200,h_750.mp4" media="(max-width: 1200px)">
        <source src="../assets/video/c_fill,g_auto,w_1920,h_1080.mp4">
      </video>  

			<div class="overlay"></div>

			<div class="container h-100">

				<div class="row align-items-center h-100">
					
					<div class="col-lg-5">
						
						<h1 class="title animate__animated animate__fadeInDown">{{ $t('header.title') }}</h1>
						<p class="mt-5 animate__animated animate__fadeInLeftBig">
							<span class="description">{{ $t('header.desription') }}</span>
							<br>
              <span class="sub-desription">{{ $t('header.sub-desription') }}</span>
						</p>
						<p class="gap-xy pt-3 animate__animated animate__fadeInUpBig">
							<a class="btn btn-outline-primary btn-round min-width-170" href="#who-we-are">
								{{ $t('header.button') }}
							</a>
						</p>

					</div>

					<div class="col-lg-6 ml-auto d-none">
					</div>

				</div>

			</div>

		</header>

		<!-- who we are -->
		<section id="who-we-are" class="section bg-lightest angled lower-end pt-30">

			<div class="container py-90" v-auto-animate>

				<header class="section-header mb-5" data-aos="zoom-in">
					<h2><i class="icon-megaphone boxed-icon fw-bold"></i></h2>
					<h3>{{ $t('menu.who-we-are') }}</h3>
				</header>

				<div class="row">

					<div class="col-md-6 col-lg-5 mx-auto" data-aos="fade-right">

						<p class="fw-bold fs-19 mb-5">
							{{ $t('who-we-are.title') }}
						</p>
						<p class="lighter">
							{{ $t('who-we-are.paragraphe-1') }}
						</p>
						<p class="lighter">
							{{ $t('who-we-are.paragraphe-2') }}
						</p>
						<p class="lighter">
							{{ $t('who-we-are.paragraphe-3') }}
						</p>
						<a href="#" class="d-none">
							En savoir plus <i class="ti-angle-right fs-10 ml-1"></i>
						</a>

					</div>

					<div class="col-md-6 col-lg-5 mx-auto d-flex align-items-center" data-aos="fade-left">
						<img src="/images/who-we-are.jpg" alt="who we are" class="border-radius-15"/>
					</div>

				</div>
			</div>

		</section>

		<!-- our services -->
		<section id="our-services" class="section bg-light angled lower-end upper-end">

			<div class="container py-90">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-genius boxed-icon fw-bold"></i></h2>
					<h2>{{ $t('menu.services') }}</h2>
				</header>

				<div class="row">
					
					<!-- custom-software -->
					<div class="col-md-6" data-aos="fade-right">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/codage-web.png" alt="Développement Web" class="col-2 col-md-3 col-lg-2" />
								<h4 class="mleft">{{ $t('services.custom-software.title') }}</h4>
							</div>
							<p class="pt-3 lighter">
								{{ $t('services.custom-software.paragraphe-1') }}
							</p>
							<ul class="lighter">
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item1.title') }}</span> : {{ $t('services.custom-software.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item2.title') }}</span> : {{ $t('services.custom-software.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item3.title') }}</span> : {{ $t('services.custom-software.list.item3.desription') }}</li>
							</ul>
							<p class="lighter">
								{{ $t('services.custom-software.paragraphe-2') }}
							</p>
						</div>
					</div>

					<!-- data-migration -->
					<div class="col-md-6" data-aos="fade-left">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/migration.png" alt="Migration de données" class="col-2 col-md-3 col-lg-2" />
								<h4 class="mleft">{{ $t('services.data-migration.title') }}</h4>
							</div>
							<p class="lighter pt-3">
								{{ $t('services.data-migration.paragraphe-1') }}
							</p>
							<p class="lighter">
								{{ $t('services.data-migration.paragraphe-2') }}
							</p>
							<ul class="lighter">
								<li> <span class="fw-900">{{ $t('services.data-migration.list.item1.title') }}</span> : {{ $t('services.data-migration.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.data-migration.list.item2.title') }}</span> : {{ $t('services.data-migration.list.item2.desription') }}</li>
							</ul>
							<p class="lighter">
								{{ $t('services.data-migration.paragraphe-3') }}
							</p>
						</div>
					</div>

					<!-- design -->
					<div class="col-md-6" data-aos="fade-right">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/ui.png" alt=" UX/UI design" class="col-2 col-md-3 col-lg-2" />
								<h4 class="mleft">{{ $t('services.design.title') }}</h4>
							</div>
							<p class="lighter pt-3">
								{{ $t('services.design.paragraphe-1') }}
							</p>
							<p class="lighter">
								{{ $t('services.design.paragraphe-2') }}
							</p>
							<ul class="lighter">
								<li> <span class="fw-900">{{ $t('services.design.list.item1.title') }}</span> : {{ $t('services.design.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.design.list.item2.title') }}</span> : {{ $t('services.design.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.design.list.item3.title') }}</span> : {{ $t('services.design.list.item3.desription') }}</li>
							</ul>
						</div>
					</div>

					<!-- maintenance -->
					<div class="col-md-6" data-aos="fade-left">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/entretien.png" alt="Maintenance logicielle" class="col-2 col-md-3 col-lg-2" />
								<h4 class="mleft">{{ $t('services.maintenance.title') }}</h4>
							</div>
							<p class="lighter pt-3">
								{{ $t('services.maintenance.paragraphe-1') }}
							</p>
							<p class="lighter">
								{{ $t('services.maintenance.paragraphe-2') }}
							</p>
							<ul class="lighter">
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item1.title') }}</span> : {{ $t('services.maintenance.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item2.title') }}</span> : {{ $t('services.maintenance.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item3.title') }}</span> : {{ $t('services.maintenance.list.item3.desription') }}</li>
							</ul>
							<p class="lighter">
								{{ $t('services.maintenance.paragraphe-3') }}
							</p>
						</div>
					</div>

				</div>
				
			</div>

		</section>

		<!-- our clients -->
		<section id="our-clients" class="section py-30">

			<div class="container py-90">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-trophy boxed-icon fw-bold"></i></h2>
					<h2>{{ $t('menu.customers') }}</h2>
					<hr>
					<p class="lead">
						{{ $t('customers.title') }}
					</p>
					<p class="lighter">
						{{ $t('customers.description') }}
					</p>
				</header>

				<div class="row gap-y py-70">

					<div class="d-flex align-items-center justify-content-center mh-80" v-auto-animate>
						<Transition name="slide" v-for="slide in visible_slides" :key="slide">
							<div class="slide d-flex align-items-center justify-content-center" v-if="slide.show" >
								<a class="" :href="slide.link" target="_blank">
									<img :src="slide.source" :title="slide.title" :class="slide.class"/>
								</a>
							</div>
						</Transition>
					</div>
					
				</div>

			</div>

		</section>

		<!-- rendez-vous-->
		<section id="rendez-vous" class="section bg-light angled upper-end py-30">

			<div class="container py-70">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-calendar boxed-icon fw-bold"></i></h2>
					<h3>{{ $t('get-in-touch.title') }}</h3>
				</header>

				<div class="row py-30">
					
					<div class="col-md-10 col-lg-6 mx-auto" data-aos="fade-left">

						<p class="lighter fs-17">
							{{ $t('get-in-touch.paragraphe-1') }}
						</p>

						<p class="">
							{{ $t('get-in-touch.paragraphe-2') }}
						</p>

						<ul class="lighter fs-15">
								<li> {{ $t('get-in-touch.list1.item1') }}</li>
								<li> {{ $t('get-in-touch.list1.item2') }}</li>
								<li> {{ $t('get-in-touch.list1.item3') }}</li>
						</ul>

						<p class="">
							{{ $t('get-in-touch.paragraphe-3') }}
						</p>

						<ul class="lighter fs-15">
								<li> {{ $t('get-in-touch.list2.item1') }}</li>
								<li class="d-none"> {{ $t('get-in-touch.list2.item2') }}</li>
								<li> {{ $t('get-in-touch.list2.item3') }}</li>
						</ul>

						<p class="gap-xy pt-3 animate__animated animate__fadeInUpBig text-center">
							<a class="btn btn-outline-primary btn-round min-width-170" 
									href="https://calendly.com/rkissi/60min?back=1" target="_blank">
								{{ $t('get-in-touch.button') }}
							</a>
						</p>
					</div>

				</div>
			</div>

		</section>

		<!-- form -->
		<section class="section d-none">

			<div class="container py-100">

				<h2 class="text-center fw-200">Join KSR Services</h2>
				<form class="row gap-y mt-7" method="GET">
					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="text" name="name" placeholder="Name">
					</div>

					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="email" name="email" placeholder="Email">
					</div>

					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="password" name="password" placeholder="Password">
					</div>

					<div class="col-md-6 col-lg-3">
						<button class="btn btn-success btn-md btn-block"> Get Started </button>
					</div>
				</form>
			</div>

		</section>

	</main>
	<!-- /.main-content -->

	<footer class="footer bg-lightest">

		<div class="container">
			
			<div class="row align-items-center">
				
				<div class="col-md-4">
					<img class="width-60 my-3" src="/images/logo.png" alt="KSR Services" />
					<div class="ms-4">
						<p class=""> © KSR Services 2024 </p>
						<div class="social social-sm social-hover-bg-brand">
							<a class="social-linkedin" href="https://fr.linkedin.com/company/ksr-services-co?original_referer=https://ksrservices.co/" target="_blank">
								<i class="icon-linkedin"></i>
							</a>
							<a class="social-facebook" href="https://www.facebook.com/KSR-services-102087375054921" target="_blank">
								<i class="icon-facebook"></i>
							</a>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="nav nav-bold nav-uppercase nav-center d-none">
						<a class="nav-link" href="#">link</a>
					</div>
				</div>

				<div class="col-md-4">
					<ul class="style-none fs-15">
						<li class="mbottom-10">
							<i class="icon-map-pin"></i> 
							<a class="ms-2" 
								href="https://goo.gl/maps/yJkhDNsNycakg1Nz9"
								target="_blank">
								57 Rue Amsterdam, 75008 PARIS
							</a>
						</li>
						<li class="mbottom-10">
							<i class="icon-envelope"></i> 
							<a class="ms-1" href="mailto:contact@ksrservices.co">contact@ksrservices.co</a>
						</li>
						<li class="">
							<i class="icon-phone"></i> 
							<a class="ms-2" href="tel:+33766023140">+33 6 50 31 29 32</a>
						</li>
					</ul>
				</div>

			</div>

			<div class="row py-3 align-items-center d-none">
				
				<div class="col-md-4 d-flex float-start align-items-center">
					
				</div>

				<div class="col-md-4">
					
				</div>

				<div class="col-md-4 text-center text-md-right">
					
				</div>

			</div>

		</div>

	</footer>

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import AOS from "aos";
import { useI18n } from 'vue-i18n';

export default {

  name: 'Home',

  components: {
    
  },

	setup() {
		// locale
    const { locale } = useI18n();
    const currentLanguage = ref(locale.value);

		// sticky navbar
		const isScrolled = ref(false);
		const handleScroll = () => {
      isScrolled.value = window.scrollY > 500; // Change 100 to the scroll threshold you prefer
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      currentLanguage,
			isScrolled,
    };
  },

  data () {
    return {
			selectedLanguage: '',
      isNavbarOpen: false,
      visible_slide_index : 0,
      visible_slides : [],
      visible_slides_size : 0,
      slides : [
        {
          link : "https://www.japautoauto.com/",
          source : "/images/clients/japauto.png",
          title : "japauto",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.barenstark.com/",
          source : "/images/clients/barenstark.png",
          title : "barenstark",
          class : "width-80 mx-auto",
          show : true
        },
        {
          link : "https://www.elyseemotor.com/",
          source : "/images/clients/elysee-motor.png",
          title : "Elysee Motor",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.kia-idfa.com/",
          source : "/images/clients/kia-idfa.png",
          title : "kia-idfa",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.patrickpons.com/",
          source : "/images/clients/patrick-pons.png",
          title : "patrick-pons",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.zebecane.fr/",
          source : "/images/clients/zebecane.png",
          title : "zebecane",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.scor.com",
          source : "/images/clients/scor.png",
          title : "scor",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.abeille-assurances.fr",
          source : "/images/clients/abeille-assurance.png",
          title : "aviva",
          class : "width-50 mx-auto",
          show : true
        }
      ]
    }
  },
	
  mounted() {

		if(this.currentLanguage == 'en')
			this.selectedLanguage = 'EN'

		if(this.currentLanguage == 'fr')
			this.selectedLanguage = 'FR'

		AOS.init();

    this.visible_slides_size = this.initVisibleSlidesSize();

    for (let i = 0; i < this.visible_slides_size; i++) {
			this.visible_slides.push(this.slides[i]);
		}

    window.setInterval(() => {

			this.visible_slides.shift();

      this.visible_slide_index++;
      if(this.visible_slide_index>=this.slides.length)
        this.visible_slide_index=0;

      var index = this.visible_slide_index;
      for (let i = 1; i < this.visible_slides_size; i++) {
        index++;
        if(index>=this.slides.length)
          index=0;
      }
			this.visible_slides.push(this.slides[index]);

    }, 1500);

  },

  methods: {

    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },

    initVisibleSlidesSize(){

      // small
      if(window.matchMedia("only screen and (max-width: 480px)").matches)
        return 2;

      // tablet
      if(window.matchMedia("only screen and (max-width: 990px)").matches)
        return 3;

      // other deveices
      return 4;
    },
	
		async changeLanguage(value, language) {
			this.selectedLanguage = language;
			this.$i18n.locale = value;
		},

		scrollTo(targetSelector) {
      this.isNavbarOpen = false;
      
      const targetElement = document.querySelector(targetSelector);
      if (targetElement) {
        //console.info("targetElement", targetElement);
        const targetPosition = targetElement.offsetTop - 41;
        window.scrollTo({
          top : targetPosition,
          behavior: 'smooth',
          block: 'start',
        });
      }

    },

  }
}
</script>

<style scoped>

.navbar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slide {
  float: left;
  height: 100%;
	width: 284px;
	min-height: 80px;
}

.slide img {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>

